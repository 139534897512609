import dayjs from "dayjs";

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function convertDate(time, format = "DD-MM-YYYY") {
  return dayjs(time).format(format);
}

export function numberWithDelimiter(number, delimiter = ".") {
  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
}
