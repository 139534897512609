const BACKEND_URL = "http://14.225.0.205";

export default {
  AUTH: `${BACKEND_URL}/admin/auth`,
  CATEGORIES: `${BACKEND_URL}/admin/categories`,
  SEARCH_CATEGORY: `${BACKEND_URL}/admin/categories/search`,
  POSTS: `${BACKEND_URL}/admin/posts`,
  SEARCH_POSTS: `${BACKEND_URL}/admin/posts/search`,
  UPLOAD: `${BACKEND_URL}/admin/upload`,
};
