<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="isMobile && opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <sidebar class="sidebar-container" />
    <div :class="{ hasTagsView: true }" class="main-container">
      <div class="fixed-header">
        <navbar />
        <tags-view />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppMain from "./components/AppMain";
import Sidebar from "./components/Sidebar/index";
import Navbar from "./components/Navbar";
import TagsView from "./components/TagsView";
import store from "@/store";
export default {
  name: "Layout",
  components: {
    AppMain,
    Sidebar,
    Navbar,
    TagsView,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    ...mapState({
      opened: (state) => state.sidebar.opened,
      withoutAnimation: (state) => state.sidebar.withoutAnimation,
    }),
    classObj() {
      return {
        hideSidebar: !this.opened,
        openSidebar: this.opened,
        withoutAnimation: this.withoutAnimation,
        mobile: this.isMobile,
      };
    },
  },
  watch: {
    $route() {
      if (this.isMobile && this.opened) {
        store.dispatch("sidebar/closeSideBar", { withoutAnimation: false });
      }
    },
  },
  mounted() {
    if (window.innerWidth <= 992) {
      this.isMobile = true;
      store.dispatch("sidebar/closeSideBar", { withoutAnimation: true });
    } else {
      this.isMobile = false;
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 992) {
        this.isMobile = true;
        store.dispatch("sidebar/closeSideBar", { withoutAnimation: true });
      } else {
        this.isMobile = false;
      }
    });
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("sidebar/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}
.mobile .fixed-header {
  width: 100%;
}
</style>
