import Vue from "vue";
import VueRouter from "vue-router";

// Layout
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "categories",
    children: [
      {
        path: "categories",
        component: () => import("@/views/category/index"),
        name: "Categories",
        meta: {
          title: "Quản lý danh mục bài viết",
          icon: "el-icon-menu",
          affix: true,
        },
      },
    ],
  },
  {
    path: "/category",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "create",
        component: () => import("@/views/category/create"),
        name: "CreateCategory",
        meta: {
          title: "Tạo danh mục bài viết",
        },
      },
      {
        path: "update/:id",
        component: () => import("@/views/category/update"),
        name: "UpdateCategory",
        meta: {
          title: "Cập nhật danh mục bài viết",
        },
      },
    ],
  },
  {
    path: "/postses",
    component: Layout,
    children: [
      {
        path: "",
        component: () => import("@/views/posts/index"),
        name: "Posts",
        meta: {
          title: "Quản lý bài viết",
          icon: "el-icon-document",
          affix: false,
        },
      },
    ],
  },
  {
    path: "/posts",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "create",
        component: () => import("@/views/posts/create"),
        name: "CreatePosts",
        meta: {
          title: "Thêm mới bài viết",
        },
      },
      {
        path: "update/:id",
        component: () => import("@/views/posts/update"),
        name: "UpdatePosts",
        meta: {
          title: "Cập nhật bài viết",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login.vue"),
    hidden: true,
    meta: {
      title: "Đăng nhập",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
