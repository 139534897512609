import router from "./router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import getPageTitle from "./utils/get-page-title";
import { loginInfo } from "@/services/auth";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // start progress bar
  if (!loginInfo() && to.name !== "Login") {
    router.push("/login");
  } else if (to.name === "Login" && loginInfo()) {
    router.push("/");
  } else {
    NProgress.start();
    // set page title
    document.title = getPageTitle(to.meta.title);
    // Handle auth logic here
    // ...
    setTimeout(() => {
      next();
      NProgress.done();
      window.scroll(0, 0);
    }, 100);
  }
});
