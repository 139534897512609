import request from "@/utils/request";
import Cookies from "js-cookie";
import ENDPOINT from "@/config/endpoint";
const qs = require("qs");

export async function login(data) {
  return await request({
    url: `${ENDPOINT.AUTH}/login`,
    method: "post",
    data: qs.stringify(data),
  });
}

export function updateLoginInfo(data) {
  Cookies.set("login_info", JSON.stringify(data));
}

export function loginInfo() {
  try {
    return JSON.parse(Cookies.get("login_info"));
  } catch (_) {
    return null;
  }
}

export function logout() {
  Cookies.remove("login_info");
  window.location.reload();
}
